<template>
  <div class="sect-service-pqr">
    <div class="cont-center">
      <img src="../assets/img/pexels-lusina-5721014.jpg" alt="" />
    </div>
    <v-container class="service-two">
      <v-row>
        <v-col cols="12" md="6">
          <div>
            <p class="tit-form">Formulario de radicación</p>
            <v-form
              ref="form"
              v-model="valid"
              method="post"
              enctype="multipart/form-data"
              lazy-validation
            >
              <v-row>
                <div class="col-custom-12">
                  <v-select
                    outlined
                    color="#60060b"
                    label="Solicitud"
                    :items="itemProduct"
                    v-model="tipo"
                    ref="tipo"
                    :rules="ruleInput"
                    required
                  ></v-select>
                </div>
                <div class="col-custom">
                  <v-text-field
                    outlined
                    color="#60060b"
                    label="Nombre completo"
                    ref="name"
                    v-model="name"
                    :rules="ruleInput"
                    required
                  ></v-text-field>
                </div>
                <div class="col-custom">
                  <v-text-field
                    outlined
                    color="#60060b"
                    label="Número de identificación"
                    ref="cedula"
                    v-model="cedula"
                    :rules="ruleInput"
                    required
                  ></v-text-field>
                </div>
                <div class="col-custom">
                  <v-text-field
                    outlined
                    color="#60060b"
                    label="Dirección de residencia"
                    ref="direccion"
                    v-model="direccion"
                    :rules="ruleInput"
                    required
                  ></v-text-field>
                </div>
                <div class="col-custom">
                  <v-text-field
                    outlined
                    color="#60060b"
                    label="Su número de celular"
                    placeholder="3xxx-xxxxxxxx"
                    v-model="movil"
                    :rules="ruleInput"
                    ref="movil"
                    required
                  ></v-text-field>
                </div>
                <div class="col-custom-12">
                  <v-text-field
                    outlined
                    color="#60060b"
                    label="Correo electrónico"
                    ref="email"
                    v-model="email"
                    :rules="ruleEmail"
                    required
                  ></v-text-field>
                </div>
                <div class="col-custom-12">
                  <span
                    >El archivo solo puede ser de tipo word, pdf o imagen y un
                    maximo de 5MB.</span
                  >
                  <v-file-input
                    ref="file"
                    v-model="file"
                    accept="application/msword, application/pdf, image/*"
                    label="Anexar Archivo"
                  ></v-file-input>
                  <span class="error-file" v-if="this.errorFile"
                    >El archivo es mayor a 5MB o el formato del documento anexo
                    no es permitido.</span
                  >
                </div>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="info"
                    label="Digite su solicitud"
                    v-model="info"
                    placeholder="Cuéntanos como podemos ayudarte"
                    :rules="ruleInput"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!valid"
                @click="validate"
                elevation="2"
                color="#eca72c"
                large
                >Radica PQR</v-btn
              >
            </v-form>
          </div>
        </v-col>
        <v-col class="no-padding" cols="12" md="6">
          <div class="copy_bg">
            <p class="tit">Proceso de atención de PQRS</p>
            <p>
              En Arpol SAS queremos que siempre estés en contacto, Actualiza tus
              datos y comunícate con nosotros de una manera directa, clara,
              sencilla y sin ningún costo.
            </p>
            <p class="tit">Selecciona la opción que prefieras</p>
            <p>
              Elige una de las siguientes opciones para comunicarte con nuestros
              asesores virtuales y obtener información sobre productos,
              servicios.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <SubFooter />
    <v-snackbar color="#000" v-model="snackbar" :timeout="timeout">
      <span :style="this.color">{{ text }} </span>
      <template v-slot:action="{ attrs }">
        <v-btn color="#fff" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SubFooter from "@/components/SubFooter.vue";
import { mapActions } from "vuex";
export default {
  name: "pqr",
  components: {
    SubFooter,
  },
  data: () => ({
    snackbar: false,
    text: "Gracias en minutos uno de nuestros asesores le contactara.",
    color: { color: "#fff" },
    timeout: -1,
    valid: true,
    name: null,
    radicado: null,
    file: null,
    direccion: null,
    cedula: null,
    movil: null,
    email: null,
    emailpqr: "gerente@arpol.com.co",
    info: null,
    tipo: null,
    errorFile: null,
    // eslint-disable-next-line prettier/prettier
    ruleInput: [(v) => !!v || "Es requerido"],
    ruleEmail: [
      // eslint-disable-next-line prettier/prettier
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "es requerida",
    ],
    itemProduct: [
      "Peticiones",
      "Quejas y reclamos",
      "Solicitudes",
      "Felicitaciones y agradecimientos",
    ],
    interval: null,
    isPlaying: false,
  }),
  methods: {
    ...mapActions(["sendPqr"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.submitInfo();
      }
    },
    submitInfo() {
      try {
        //const formData = new FormData();

        let formData = new FormData();
        let maximumSize = 5 * 1024 * 1024; // In MegaBytes
        this.radicado = new Date().toISOString();
        if (this.file != null) {
          //let formData = new URLSearchParams();
          // formData.append("time", this.date);
          formData.append("tipo", this.tipo);
          formData.append("radico", this.radicado);
          formData.append("emailpqr", this.emailpqr);
          formData.append(
            "subject",
            `PQR - Cliente identificado con  ${this.cedula}`
          );
          formData.append("email", this.email);
          formData.append("cedula", this.cedula);
          formData.append("file", this.file);
          formData.append("direccion", this.direccion);
          formData.append("info", this.info);
          formData.append("name", this.name);
          formData.append("movil", this.movil);
          //console.log("archivo", this.file);
          if (
            this.file.size <= maximumSize &&
            this.file.type != "application/zip" &&
            this.file.type != "application/vnd.rar" &&
            this.file.type != "application/x-ms-dos-executable"
          ) {
            //console.log("pasa");
            this.sendPqr(formData);
            this.$refs.form.reset();
            this.color = { color: "#fff" };

            this.snackbar = true;
          } else {
            this.errorFile = true;

            this.snackbar = true;
            this.text =
              "Parece que hay problema con alguna información del formulario o el formato del documento anexo no es permitido.";
            this.color = { color: "rgb(236, 167, 44)" };
          }
        } else {
          //let formData = {};
          //console.log("sin archivo adjunto");
          formData.append("tipo", this.tipo);
          formData.append("radico", this.radicado);
          formData.append("emailpqr", this.emailpqr);
          formData.append(
            "subject",
            `PQR - Cliente identificado con  ${this.cedula}`
          );
          formData.append("email", this.email);
          formData.append("cedula", this.cedula);
          formData.append("file", this.file);
          formData.append("direccion", this.direccion);
          formData.append("info", this.info);
          formData.append("name", this.name);
          formData.append("movil", this.movil);
          //console.log("archivo", this.file);
          //console.log("formdata antes de la accion", formData);
          this.sendPqr(formData);
          this.$refs.form.reset();
          this.color = { color: "#fff" };

          this.snackbar = true;
        }
        //console.log("file size", this.file.size);

        /* formData.tipo = this.tipo;
        formData.radicado = this.radicado;
        formData.emailpqr = this.emailpqr;
        formData.subject = `PQR - Cliente identificado con  ${this.cedula}`;
        formData.email = this.email;
        formData.cedula = this.cedula;
        formData.file = this.file;
        formData.direccion = this.direccion;
        formData.info = this.info;
        formData.name = this.name;
        formData.movil = this.movil; */
        //formData = JSON.stringify(formData);
        // send data
        //this.sendPqr(formData);
        //this.$refs.form.reset();
      } catch (error) {
        this.snackbar = true;
        this.text =
          "Parece que hay problema con alguna información del formulario.";
        //console.log(error);
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "../assets/scss/base.scss";
.sect-service-pqr {
  padding-top: 87px;
}
.no-padding {
  padding: 0;
  @media #{$tablet-up} {
    padding: 12px;
  }
}
p.tit-form {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}
.cont-center {
  //padding: 5px;
  img {
    object-fit: cover;
    height: 150px;
    width: 100%;
    @media #{$tablet-up} {
      height: 320px;
    }
  }
}
.copy_bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px;
  background-color: $rosewood;
  p {
    font-size: 1.2rem;
    color: #fff;
    &.tit {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}
.col-custom {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px;
}
.col-custom-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
}
span.error-file {
  color: $color-01;
}
</style>
